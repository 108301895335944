<template>
  <t-body>
    <t-sidebar class="search-sidebar">
      <h3>{{ $t('pages.search.heading') }}</h3>

      <validation-observer ref="searchValidator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(search)">
          <t-grid compact>
            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="radius"
                  :name="$t('fields.search_radius')"
                  rules="required|integer"
                >
                  <SearchRadiusSelect v-model="radius" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="graduation_type_ids"
                  :name="$t('fields.graduation_types')"
                >
                  <GraduationTypeSelect v-model="searchForm.graduation_type_ids" multiple />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper vid="occupation_ids" :name="$t('fields.occupations')">
                  <OccupationSelect v-model="searchForm.occupation_ids" multiple />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <div
                style="
                  flex-direction: row-reverse;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <t-button-loading :disabled="invalid" :loading="searchLoading" native-type="submit">
                  {{ $t('global.actions.search') }}
                </t-button-loading>
              </div>
            </t-grid-row>

            <t-grid-row>
              <i18n path="pages.search.alert_hint" tag="span">
                <NuxtLink :to="{ name: $RouteEnum.ALERTS.CREATE }">
                  {{ $t('fields.alerts') }}
                </NuxtLink>
              </i18n>
            </t-grid-row>
          </t-grid>
        </t-form>
      </validation-observer>
    </t-sidebar>

    <t-page inline>
      <t-page id="map" class="map" />
      <EmptyState
        v-if="!hasResults"
        image="internships-empty"
        :title="$t('pages.search.results.empty')"
      />

      <div v-else class="result-area">
        <div class="select-bar">
          <t-badge>
            {{
              $t('fields.selected_items_count', { count: pingForm.applicant_ids.length })
            }}
          </t-badge>

          <EmailTemplateSelect
            v-model="pingForm.email_template_id"
            style="flex: 1; margin: 0 2rem"
            :query="templateQuery"
            @original="previewData.template = $event"
          />

          <t-button-loading :disabled="!pingable" :loading="false" @click="preview">
            {{ $t('global.actions.preview') }}
          </t-button-loading>
        </div>

        <div class="result-body">
          <t-search-results-list
            v-model="pingForm.applicant_ids"
            :results="results"
          />
          <div class="result-cta">
            <t-button
              :loading="false"
              outlined
              @click="preview"
            >
              {{ $t('global.actions.contact') }}
            </t-button>
          </div>

          <!-- <t-card inline style="width: fit-content;">
            <h3 slot="header">
              {{ $t('pages.search.results.heading') }}
            </h3>

            <t-table>
              <t-table-row slot="head">
                <t-table-head>
                  <t-checkbox v-model="allSelected" />
                </t-table-head>
                <t-table-head>{{ $t('fields.applicant') }}</t-table-head>
                <t-table-head>{{ $t('fields.organization') }}</t-table-head>
                <t-table-head>{{ $t('fields.occupation') }}</t-table-head>
                <t-table-head>{{ $t('fields.distance') }}</t-table-head>
                <t-table-head>{{ $t('fields.graduation_type') }}</t-table-head>
                <t-table-head>{{ $t('fields.pinged') }}</t-table-head>
              </t-table-row>

              <t-table-row v-for="result in results" :key="result.applicant.id">
                <t-table-cell>
                  <t-checkbox
                    v-model="pingForm.applicant_ids"
                    :native-value="result.applicant.id"
                    :disabled="result.pinged"
                  />
                </t-table-cell>
                <t-table-cell>{{ result.applicant.user.fullname }}</t-table-cell>
                <t-table-cell>{{ result.applicant.organization_name }}</t-table-cell>
                <t-table-cell>{{ result.applicant.occupation.name }}</t-table-cell>
                <t-table-cell>{{ result.distance }}km</t-table-cell>
                <t-table-cell>{{ result.applicant.graduation_type.translation }}</t-table-cell>
                <t-table-cell><PingedState :state="result.pinged" /></t-table-cell>
              </t-table-row>
            </t-table>
          </t-card> -->
        </div>
      </div>
    </t-page>

    <portal to="modals">
      <t-modal
        v-if="previewData.visible"
        size="lg"
        class="preview-modal"
        @modal-close="previewData.visible = false"
      >
        <a
          href="#"
          class="t-modal__close"
          role="button"
          @click.prevent="previewData.visible = false"
        >
          <t-icon icon="times" />
        </a>
        <h3>{{ $t('pages.email_template.preview.heading') }}</h3>
        <TemplateRenderer
          :layout="previewData.template.email_layout"
          :profile="previewData.template.organization_profile"
          :definitions="previewData.template.definitions"
        />
        <div class="t-modal__footer">
          <t-button-loading :loading="pingLoading" @click="ping">
            {{ $t('pages.email_template.actions.ping_applicants') }}
          </t-button-loading>
        </div>
      </t-modal>
    </portal>
  </t-body>
</template>

<script>
import SearchRadiusSelect from '~/components/EnumSelect/SearchRadiusSelect';
import GraduationTypeSelect from '~/components/ResourceSelect/GraduationTypeSelect';
import OccupationSelect from '~/components/ResourceSelect/OccupationSelect';
import EmailTemplateSelect from '~/components/ResourceSelect/EmailTemplateSelect';
import EmailTemplate from '~/models/EmailTemplate';
import SearchRadiusEnum from '~/enums/SearchRadiusEnum';
import TValidationWrapper from '~/components/TValidationWrapper';
import Alert from '~/models/Alert';
import EmptyState from '~/components/EmptyState';
import useSearchMap from '~/composables/useSearchMap';
import TemplateRenderer from '~/pages/shared/email-templates/partials/TemplateRenderer';
import TSearchResultsList from '~/components/SearchResults/TSearchResultsList';
import Ping from '~/models/Ping';

export default {
  components: {
    EmptyState,
    TValidationWrapper,
    SearchRadiusSelect,
    GraduationTypeSelect,
    OccupationSelect,
    EmailTemplateSelect,
    TemplateRenderer,
    TSearchResultsList,
  },

  setup() {
    const {
      addResultMarker,
      resetResultMarkers,
      buildMap,
      position,
      radius,
      makeUi,
    } = useSearchMap();
    radius.value = SearchRadiusEnum.TWENTYFIVE;

    return { addResultMarker, resetResultMarkers, buildMap, position, radius, makeUi };
  },

  data: () => ({
    searchLoading: false,
    pingLoading: false,

    previewData: {
      template: null,
      visible: false,
    },

    searchForm: null,
    pingForm: null,
    results: [],

    templateQuery: new EmailTemplate()
      .include('emailLayout', 'profile')
      .append('emailLayout.template'),
  }),

  async fetch() {
    this.searchForm = this.getSearchForm();
    this.pingForm = this.getPingForm();

    const alertId = this.$route.query.alert;
    if (alertId) {
      const alert = await Alert.$find(alertId);
      this.applyAlert(alert);
      this.search();
    }
  },

  computed: {
    pingable() {
      return this.pingForm.applicant_ids.length !== 0 && !!this.pingForm.email_template_id;
    },

    hasResults() {
      return this.results.length > 0;
    },

    selectable() {
      return this.results.filter(result => !result.pinged);
    },

    allSelected: {
      set(value) {
        if (!value) {
          this.pingForm.applicant_ids = [];
          return;
        }
        this.pingForm.applicant_ids = this.selectable.map(result => result.applicant.id);
      },

      get() {
        return (
          this.pingForm.applicant_ids.length === this.selectable.length &&
          this.selectable.length !== 0
        );
      },
    },
  },

  async mounted() {
    const { latitude, longitude } = this.$auth.me.organization.address.position;

    this.position = {
      lat: latitude,
      lng: longitude,
    };

    await this.buildMap(document.getElementById('map'), { zoom: 8 });

    this.makeUi();
  },

  methods: {
    applyAlert(alert) {
      this.position = {
        lat: alert.parameters.position.latitude,
        lng: alert.parameters.position.longitude,
      };
      this.radius = alert.parameters.radius;
      this.searchForm = this.getSearchForm(alert);
    },

    getSearchForm(alert = null) {
      return {
        graduation_type_ids: alert?.graduation_type_ids ?? [],
        occupation_ids: alert?.occupation_ids ?? [],
        organization_id: this.$auth.me.organization_id,
      };
    },

    getSearchPayload() {
      return {
        radius: this.radius,
        position: {
          latitude: this.position.lat,
          longitude: this.position.lng,
        },

        ...this.searchForm,
      };
    },

    async search() {
      this.searchLoading = true;
      this.results = [];
      this.resetResultMarkers();
      try {
        const response = await this.$axios.post('/v1/search', this.getSearchPayload());

        if (response.status === 204) {
          await this.$notify.warning(this.$t('notifications.search.no_results'));
          return;
        }

        this.results = response?.data?.data;

        this.addMarkers(this.results);
      } catch (e) {
        this.$axios.handleError(e, this.$refs.searchValidator);
      } finally {
        this.searchLoading = false;
      }
    },

    addMarkers(results) {
      results.forEach((result) => {
        this.addResultMarker(result.applicant.id, {
          lat: result.position.latitude,
          lng: result.position.longitude,
        });
      });
    },

    async preview() {
      if (!this.pingable) {
        await this.$notify.warning(this.$t('Please select an email template from the dropdown and at least one applicant.'));
        return;
      }
      this.previewData.visible = true;
    },

    getPingForm() {
      return {
        applicant_ids: [],
        email_template_id: null,
        organization_id: this.$auth.me.organization_id,
      };
    },

    getPingPayload() {
      return {
        ...this.pingForm,
      };
    },

    async ping() {
      this.pingLoading = true;
      this.resetResultMarkers();
      try {
        await new Ping(this.getPingPayload()).save();
        this.$notify.success(this.$t('notifications.applicant.pinged'));
        this.pingForm = this.getPingForm();
        this.search();
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.pingLoading = false;
        this.previewData.visible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.result-area {
  width: 100%;
  height: 100%;
}
.select-bar {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #f0f0f3;
  height: 70px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.result-body {
  height: calc(100% - 70px);
  padding: 1rem;
  overflow: auto;
}

.preview-modal {
  .t-modal__dialog {
    height: 80%;
  }
  .t-modal__content {
    height: 100%;
  }
}
.map {
  height: 245px;
}
.result-cta {
  text-align: right;
  margin-top: 1rem;
}
.search-sidebar {
  min-width: 20rem;
}
</style>
